<template>
  <div>
    <div class="container-fluid">
      <PageTitle :noAdd="true" />

      <div v-if="isList" class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8">
              <h5 class="card-title">{{pageTitle}} List</h5>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table product-overview table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Legal Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="data.data===false">
                <td colspan="99" class="text-center">
                  <Loader class="mt10 mb10" />
                </td>
              </tr>
              <tr v-for="(v,k) in data.data" :key="k">
                <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>

                <td><span v-tooltip="'Last updated : ' + v.al_updated_date.dates('format2')">{{ v.al_title }}</span></td>

                <td>
                  <router-link :to="{name:$route.name, params:{pageSlug:v[idKey]}}"
                    class="text-inverse icon_action act_icon" v-tooltip="'Edit'">
                    <i class="ti-marker-alt"></i>
                  </router-link>
                </td>
              </tr>
              <tr v-if="notFound">
                <td colspan="99" class="text-center">
                  <h4>{{notFound}}</h4>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer" v-if="data.data!==false&&data.data.length">
          <div class="row">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
              <div class="pagination-flat float-right">
                <Pagination :data="data" :limit="2" @pagination-change-page="doPaginate">
                  <span slot="prev-nav"><i class="icon-arrow-left12"></i></span>
                  <span slot="next-nav"><i class="icon-arrow-right13"></i></span>
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-else>
        <div class="card">
          <ul class="nav nav-tabs customtab" role="tablist">
            <li class="nav-item"> <a class="nav-link active" @click="changeTab('main')" id="main-nav" data-toggle="tab" href="#main" role="tab"><span
                  class="hidden-sm-up"></span> <span class="hidden-xs-down">Legal Form</span></a> </li>
            <li class="nav-item"> <a class="nav-link" @click="changeTab('SEO')" id="seo-nav" data-toggle="tab" href="#seo" role="tab"><span
                  class="hidden-sm-up"></span> <span class="hidden-xs-down">SEO Setting</span></a> </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane fade show active" id="main" role="tabpanel">
              <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
                <form @submit.prevent="handleSubmit(doSubmitCRUD)">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12">

                        <div class="form-group">
                          <label class="control-label">
                            Title
                            <span class="text-danger mr5">*</span>
                          </label>
                          <input v-model="row.al_title" type="text" class="form-control"
                            placeholder="Title" required />
                          <VValidate name="Title" v-model="row.al_title" :rules="mrValidation.al_title" />
                        </div>

                        <div class="form-group">
                          <label class="control-label">
                            Description
                            <span class="text-danger mr-5">*</span>
                          </label>
                          <!-- <CKEditor 
                            :value.sync="row.al_description"
                          /> -->
                          <tinymce id="desc" @editorInit="tmEditorInit(row.al_description || '')" v-model="row.al_description"
                            ref="tm"
                            toolbar1='formatselect | bold italic underline | numlist bullist outdent indent | link | link image |'
                            :other_options='{images_upload_url:apiUrl+"/ajax/upload?pageType=editor",content_style: "body { font-size: .875rem; font-family: Arial;}",height : "200",menubar: false,branding:false}'
                            >
                          </tinymce>
                          <!-- <vue-editor  v-model="row.al_description" :editorToolbar="toolbarOptions"></vue-editor> -->
                          <!-- <QuillEditor :value.sync="row.al_description"></QuillEditor> -->
                          <VValidate name="Description" v-model="row.al_description" :rules="mrValidation.al_description" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="text-right">
                      <button type="submit" class="btn btn-rounded btn-info">Submit</button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
            <div class="tab-pane fade" id="seo" role="tabpanel">
              <div class="" v-if="isMeta">
                <ValidationObserver v-slot="{ handleSubmit }" ref="VFormSeo">
                  <form @submit.prevent="handleSubmit(doSubmitSeo)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="control-label">Meta Title <a
                                v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                                class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                            <input @input="checkDisabled" v-model="rowSEO.sss_title" type="text" id="firstName" class="form-control"
                              placeholder="Meta Title">
                            <VValidate name="Meta Title" v-model="rowSEO.sss_title" :rules="{required: 1,max : 70}" 
                            />
                          </div>

                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="" class="control-label">
                              Meta Keyword
                              <a class="help_info" v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                                ?
                              </a>
                              <span class="text-danger mr5">*</span>
                            </label>
                            <input-tag @input="checkDisabled" v-model="rowSEO.sss_keyword"></input-tag>
                            <VValidate name="Keyword" v-model="rowSEO.sss_keyword" rules="required" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="control-label">Meta Description <a
                                v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                                class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                            <textarea @input="checkDisabled" v-model="rowSEO.sss_desc" 
                             class="form-control" rows="4"></textarea>

                            <VValidate name="Meta Description" v-model="rowSEO.sss_desc" :rules="{required: 1,max : 160}" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <div class="text-right">
                      
                        <button type="button" @click="reset" class="btn  btn-rounded btn-secondary" id="button-cancel" disabled>Cancel</button>
                        <button type="submit" class="btn btn-rounded btn-info">Submit</button>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
              <div class="" v-else>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">Meta Title <a
                            v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                            class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                            <p>{{rowSEO.sss_title}}</p>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="" class="control-label">
                          Meta Keyword
                          <a class="help_info"
                            v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                            ?
                          </a>
                          <span class="text-danger mr5">*</span>
                        </label>
                        <div></div>
                        <template v-for="(v,k) in rowSEO.sss_keyword">
                          <label class="label label-success mr-1"  :key="k">
                            {{v}}
                          </label>

                        </template>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">Meta Description <a
                            v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                            class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                        <p>{{rowSEO.sss_desc}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="form-actions text-right">
                    <a href="javascript:;" @click="editMeta()" class="btn btn-rounded btn-info">Edit</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

    </div>
  </div>
</template>

<script>
  import GlobalVue from '@libs/Global'
  import PageTitle from '@view/include/PageTitle'
  import Gen from '@libs/Gen'
  // import QuillEditor from '@/components/QuillEditor'
  
  let $ = global.jQuery
  let _ = global._
  
  export default {
    extends: GlobalVue,
    components: {
      PageTitle,
      // QuillEditor
    },
    data() {
      return {
        idKey: 'al_id',
        rowSEO : {},
        isMeta: false,
        tab: "main",
        TabSeo : JSON.parse(localStorage.getItem("SEO")),
      }
    },
    methods: {
      // SEO
      reset(){
        this.apiGetSEO()
        $('#button-cancel').attr('disabled', 'disabled')
      },
      checkDisabled(){
        $('#button-cancel').removeAttr('disabled')
      },
      changeTab(tab){
        this.tab = tab
        if(this.tab === "SEO"){
          let confSeo = JSON.stringify({ menu : `${this.$route.name}`, val : "Y"})
          localStorage.setItem("SEO", confSeo)
        }else{
          let confSeo = JSON.stringify({ menu : `${this.$route.name}`, val : "N"})
          localStorage.setItem("SEO", confSeo)
        }
      },
      editMeta(){
        this.isMeta = true
      },
      apiGetSEO() {
        console.log(this.pageSlug)
        if (this.pageSlug) this.loadingOverlay = true

        if(this.pageSlug === 2){
          Gen.apiRest(
            "/get/" + "StaticSEO/8"
          ).
          then(res => {
            this.loadingOverlay = false
            this.rowSEO = res.data.row;
          })
          .catch(() => {
            this.loadingOverlay = false
          })
        }
        if(this.pageSlug == 3){
          Gen.apiRest(
            "/get/" + "StaticSEO/12"
          ).
          then(res => {
            this.loadingOverlay = false
            this.rowSEO = res.data.row;
          })
          .catch(() => {
            this.loadingOverlay = false
          })
        }
        else{
          Gen.apiRest(
            "/get/" + "StaticSEO/9"
          ).
          then(res => {
            this.loadingOverlay = false
            this.rowSEO = res.data.row;
          })
          .catch(() => {
            this.loadingOverlay = false
          })
        }
      },

      doBackSuccessMeta(){
        this.tab = "main"
        $("#seo").removeClass("active").removeClass("show");
        $("#main").addClass("active").addClass("show");

        $("#seo-nav").removeClass("active");
        $("#main-nav").addClass("active");

        let confSeo = JSON.stringify({ menu : this.$route.name, val : "N"})
        localStorage.setItem("SEO", confSeo)
      },

      doSubmitSeo(IdFormSeo = 'VFormSeo') {
        this.rowSEO.sss_update_by = this.user.id;
        
        if(this.pageSlug === 2){
          this.rowSEO.sss_page = "Term and Conditions";
        }
        else{
          this.rowSEO.sss_page = "Privacy Policy";
        }

        return this.doSubmit(
          "/do/" + "StaticSEO",
          _.assign({
            type: 'update',
            id: this.rowSEO.sss_id
          }, this.rowSEO),
          (type, resp) => {
            if (type == 'success') {
              this.isMeta = false
              this.doBackSuccessMeta()
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IdFormSeo
        )
      },

      cleanMultipleDash(string){
        string = string.replace(/-{2,}/g, '-');
        return string;
      },
      
    },
    mounted() {
      this.apiGet()
      this.apiGetSEO()
      if(this.TabSeo !== null && this.TabSeo.menu === this.$route.name && this.TabSeo.val === "Y"){
        this.tab = 'SEO'
        $("#main").removeClass("active").removeClass("show");
        $("#seo").addClass("active").addClass("show");

        $("#main-nav").removeClass("active");
        $("#seo-nav").addClass("active");
      }
    },
    watch: {
      $route() {
        this.apiGet()
        this.apiGetSEO()
      }
    }
  }
</script>